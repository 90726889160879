import React, { Component } from 'react';
import * as d3 from "d3";


class ForceGraph extends Component {

    state = {
        updateSize: null
    }

    componentDidMount() {

        const container = d3.select('.forceWrapper');

        let width = Math.round(Number(container.style('width').slice(0, -2)));
        let height = Math.round(width * 0.60);

        const svg = container
            .select('svg')
            .attr('width', width)
            .attr('height', height);


        const nodes = [
            { id: 'A' },
            { id: 'B' },
            { id: 'C' },
            { id: 'D' },
            { id: 'E' },
            { id: 'F' },
            { id: 'G' },
            { id: 'H' },
            { id: 'I' },
            { id: 'J' }
        ];

        const links = [
            { source: 'A', target: 'B' },
            { source: 'B', target: 'C' },
            { source: 'C', target: 'D' },
            { source: 'D', target: 'E' },
            { source: 'E', target: 'F' },
            { source: 'F', target: 'G' },
            { source: 'G', target: 'H' },
            { source: 'H', target: 'I' },
            { source: 'I', target: 'J' },
            { source: 'J', target: 'A' },
            { source: 'E', target: 'B' },
            { source: 'C', target: 'H' },
            { source: 'C', target: 'J' },
            { source: 'C', target: 'A' },
            { source: 'B', target: 'C' },
            { source: 'F', target: 'D' },

        ];


        var linkSelection = svg
            .selectAll("line")
            .data(links)
            .enter()
            .append("line")
            .attr("stroke", "black")
            .attr("stroke-width", 1);


        const nodeSelection = svg
            .selectAll("circle")
            .data(nodes)
            .enter()
            .append("circle")
            .attr("r", d => (width / 100) + 15)
            .attr("fill", '#0000ff')
            .call(d3.drag()
                .on('start', dragStart)
                .on('drag', drag)
                .on('end', dragEnd)
            );

        let simulation = d3.forceSimulation(nodes);

        simulation
            .force('center', d3.forceCenter(width / 2, height / 2))
            .force('nodes', d3.forceManyBody().strength(d => width * -0.2))
            .force('collide', d3.forceCollide().radius(d => width / 30))
            .force("links", d3.forceLink(links)
                .id(d => d.id)
                .distance(d => (width / 8)))
            .on('tick', ticked);

        this.setState({simulation: simulation});

        function ticked() {
            nodeSelection
                .attr("cx", d => d.x)
                .attr("cy", d => d.y);

            linkSelection
                .attr("x1", d => d.source.x)
                .attr("y1", d => d.source.y)
                .attr("x2", d => d.target.x)
                .attr("y2", d => d.target.y)
        }

        function dragStart(d) {
            simulation.alphaTarget(0.5).restart()
            d.fx = d.x;
            d.fy = d.y;
        }

        function drag(d) {
            d.fx = d3.event.x;
            d.fy = d3.event.y;
        }

        function dragEnd(d) {
            simulation.alphaTarget(0);
            d.fx = null;
            d.fy = null;
        }

        function updateSize() {

            width = Math.round(Number(container.style('width').slice(0, -2)));
            height = Math.round(width * 0.60);

            svg
                .attr('width', (width - 10))
                .attr('height', (height - 10));

            nodeSelection
                .attr("r", d => (width / 100) + 15);

            simulation
                .force('center', d3.forceCenter(width / 2, height / 2))
                .force('collide', d3.forceCollide().radius(d => width / 40))
                .force("links", d3.forceLink(links).distance(d => width / 8))
                .alpha(0.5).restart();

        }

        this.setState({updateSize: updateSize})
        window.addEventListener('resize', updateSize);

    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.state.updateSize, false);
    }


    render() {
        return (
            <div className="forceWrapper">
                <svg />
            </div>
        );
    }
}

export default ForceGraph;