import React from 'react'
import { graphql } from "gatsby"
import Img from 'gatsby-image'

import Layout from '../components/layout'
import projectStyles from './projects.module.scss'
import ForceGraph from '../components/d3ForceGraph'

import worldVid from '../videos/worldVis.mp4'
import styleguideVid from '../videos/styleguide.mp4'

const ProjectPage = (props) => {

  return (
    <Layout>

      <div className={projectStyles.projectContainer}>

        <p className={projectStyles.compactCenter}>The work I do is highly sensitive and proprietary across technology, finance, healthcare and consumer goods clients. As a result, I can only show examples of side projects and my past start-up.</p>
        <p className={projectStyles.compactCenter}>As a constantly curious person who desires to understand how things work, my learning extends outside of my role with projects across the arts and sciences. I&nbsp;also love teaching my daughter about the natural&nbsp;world.</p>

        <div className={projectStyles.project}>
          <div className={projectStyles.projectTitleContainer}>
            <h2 className={`subHeader`}>CAPIOCA</h2>
            {/* <img className={projectStyles.logo} src="../images/cap-logo.svg" alt="capioca logo"/> */}
          </div>

          <div className={projectStyles.projectContent}>

            <div className={projectStyles.projectImageContainer}>
              <Img fluid={props.data.imageOne.childImageSharp.fluid} />
            </div>

            <div className={projectStyles.projectTextContainer}>
              <p>Co-founder, managing all aspects of the business with the main focus being the development of the service. This&nbsp;development work included a cloud based back-end, building pipelines, and two front-end user interfaces, one for the customer and the other a powerful admin&nbsp;CMS.</p>
              <p>Working with my marketing co-founder, we devised campaigns and branding material. We also nurtured a growing community of users who loved the&nbsp;service.</p>
            </div>

          </div>
        </div>

        <div className={projectStyles.project}>
          <div className={projectStyles.projectTitleContainer}>
            <h2 className={`subHeader`}>Data Visualisation</h2>
            <p className={projectStyles.compactLeft}>Data is a critical aspect of the projects I work on, but it may be hard to digest. Data visualisation helps people convert data into information and knowledge by providing an accessible platform, whether this is in the form of a dashboard or a&nbsp;graphic.</p>
          </div>

          <div className={projectStyles.projectSubContainer + ` d3-container`}>
            <ForceGraph />
            <span className="small">Exploration into force graphs and relationships between entities (feel free to click and drag&nbsp;nodes)</span>
          </div>

          <div className={projectStyles.projectSubContainer}>
            <figure>
              <video controls autoPlay loop>
                <source src={worldVid} type="video/mp4" />
              </video>
              <figcaption>Exploring world map visualisations using TopoJSON and GeoJSON (data source&nbsp;UN) </figcaption>
            </figure>
          </div>

        </div>

        <div className={projectStyles.project}>
          <div className={projectStyles.projectTitleContainer}>
            <h2 className={`subHeader`}>Component Libraries</h2>
          </div>
          <div className={projectStyles.projectSubContainer}>
            <figure className="halfPlayer">
              <video controls autoPlay loop>
                <source src={styleguideVid} type="video/mp4" />
              </video>
              <figcaption>(Example mock-up) Style guides and component libraries as part of Design Systems</figcaption>
            </figure>
          </div>
        </div>

      </div>

    </Layout>
  )
};

export default ProjectPage


export const fluidImage = graphql`
fragment fluidImage on File {
  childImageSharp {
    fluid(maxWidth: 3000) {
      ...GatsbyImageSharpFluid
    }
  }
}
`;

export const pageQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "complete.png" }) {
        ...fluidImage
    }
  }
`;